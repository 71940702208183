.term-finder-wrapper-updated {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  display: flex;
  padding: 10px;
  gap: 10px;
  .navigation-panel {
    padding: 10px 10px 0px 10px;
    .pdfveiw .webviwer {
      height: calc(100vh - 160px);
    }
  }
  
  #toc_header, #meta_header {
    padding: 15px;
    font-weight: 700;
    font-size: 16px;
    height: 50px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  .meta-content {
    transition: all ease .4s;
    max-height: 0;
    &.open {
      max-height: calc(100vh - 78px);
      border-top: 1px solid gray;
      overflow: auto;
    }
  }

  #meta-data {
    &.closed {
      width: 22px;
    }
  }
  #meta-data, #meta-term {
    box-shadow: 0px 0px 4px 0px gray;
  }
  
  .meta-info {
    font-size: 14px;
    tr:nth-child(odd) {
      font-weight: 700;
    }
    td {
      padding: 5px 5px 5px 15px;
    }
    .expandable {
      color: #1976d2;
      text-decoration: underline;
      cursor: pointer;
      white-space: pre;
    }

    .expandable-box {
      padding-left: 17px;
      margin: 1px 0px;
    }
  }

  main {
    display: flex;
    gap: 10px;
  }

  .tei-body2 {
    overflow-y: auto;
    height: calc(100vh - 210px);
  }
  
  #table-of-contents {
    max-width: 250px;
    width: 100%;
    min-width: 240px;
    border-radius: 4px;
    transition: height ease .4s;
    padding-left: 10px;
    &.open {
      height: 100%;
      #toc_header {
        border-bottom: 1px solid gray;
        display: none;
      }
    }
    ul, ol {
      ::marker {
        content: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='12' fill='%231976D2'><path d='M10.3379 3L0 3.00643V11.9936L10.3379 12L14 7.5L10.3379 3Z' fill='%231976D2'/></svg>");
      }
      overflow-y: auto;
      height: calc(100vh - 295px);
      margin: 0;
    }
    li {
      margin: 2px 10px 2px 0;
      padding: 2px 0 10px 10px;
      font-size: 16px;
      word-wrap: break-word;
      a {
        color: #1976d2;
      }
      &.selected_term a {
        color: #ffffff;
      }
      &.selected_term {
        background: #1976d2;
      }
    }
  }


  #document_body {
    max-width: calc(100% - 250px);
    p {
      padding-left: 10px;
      font-size: 14px;
    }
    p[id*=H] {
      font-weight: 700;
      text-decoration: underline;
    }
    .selected_term_body {
      background: #f7e300;
      color: #000000;
      padding: 10px 10px 10px 10px;
      width: fit-content;
    }
  }


  #custom_document_header {
    padding: 15px;
    font-weight: 700;
    font-size: 16px;
    border-bottom: 1px solid gray;
  }

  #document_header {
    display: none;
  }
  .document-content {
    padding: 0 15px;
    margin: 0;
    height: calc(100% - 30px - 200px);
    display: flex;
    gap: 10px;
    .tei-body2 {
      overflow-y: auto;
      height: 100%;
    }
  }
}

.tabs {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  .tab {
    padding: 7px 20px;
    font-weight: 600;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #1976d2;
    color: #1976d2;
    box-shadow: 0px 2px 4px 0px #1976d2;
    &.active {
      background-color: #1976d2;
      color: #ffffff;
    }
  }
}

.search-index-wrapper {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  padding-bottom: 15px;
  form {
    width: 100%;
  }
  .MuiFormControl-root {
    margin-bottom: 0;
  }
  .sort {
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #1976d2;
    color: #1976d2;
    background-color: #1976d22e;
    padding: 5px;
    white-space: nowrap;
    .asc {
      rotate: 180deg;
      transform: scaleX(-1);
    }
  }
  .reset {
    cursor: pointer;
    color: #1976d2;
  }
}

.expander {
  height: 100%;
  display: flex;
  align-items: center;
}

.summary-text {
  height: calc(100vh - 140px);
  #table-of-contents ul, #table-of-contents ol {
    height: calc(100vh - 200px);
  }
  .tei-body2 {
    height: calc(100vh - 150px);
  }
}